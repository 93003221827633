import { parse, format } from 'date-fns';
import { useTranslation } from 'next-i18next';
import type { GetHotelHomePageQuery } from '../../generated/types';
import Icon from '@dx-ui/osc-icon';

export type TripAdvisorReviewType = {
  review: NonNullable<
    NonNullable<GetHotelHomePageQuery['hotel']>['tripAdvisorLocationSummary']
  >['reviews'][number];
};

const TripAdvisorReview = ({ review }: TripAdvisorReviewType) => {
  const { t } = useTranslation('trip-advisor');
  const { user, travelDate, rating, ratingImageUrl, helpfulVotes, text, title } = review;
  const userTravelDate = review?.travelDate
    ? format(parse(review.travelDate, 'yyyy-MM', new Date()), 'MMM yyyy')
    : '';

  if (!user?.username || !travelDate || !rating || !ratingImageUrl || !title || !text) {
    return null;
  }

  return (
    <div data-testid="trip-advisor-review-sec" className="mb-10 md:flex md:flex-col">
      <div className="md:flex md:justify-between">
        <div className="md:flex md:flex-col">
          <h5
            data-testid="trip-advisor-user"
            className="mb-2 break-words text-sm font-bold md:text-lg"
          >
            {user.username}
          </h5>
          <h6 data-testid="trip-advisor-user-date" className="mb-2 text-sm md:text-lg">
            {userTravelDate}
          </h6>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            data-testid="trip-advisor-user-ratingimg"
            className="w-20"
            src={ratingImageUrl}
            alt={t('tripAdvisorReviewImgAlt', { rating })}
          />
        </div>
        {!!helpfulVotes ? (
          <div data-testid="trip-advisor-helpful-vote" className="ms-1 text-sm">
            <p className="flex items-center">
              <span className="mr-2">
                <Icon className="fill-[#00a680]" name="happy" />
              </span>
              {t('helpfulVotes', {
                count: helpfulVotes,
              })}
            </p>
          </div>
        ) : null}
      </div>
      <div>
        <h5
          data-testid="trip-advisor-review-title"
          className="mt-3 text-sm font-bold leading-5 md:mb-2 md:mt-4 md:text-lg md:leading-7"
        >
          {title}
        </h5>
        <p
          data-testid="trip-advisor-review-txt"
          className="m-0 text-sm leading-5 tracking-wide md:text-lg md:leading-7"
        >
          {`"${text}"`}
        </p>
      </div>
    </div>
  );
};

export default TripAdvisorReview;
