import Icon from '@dx-ui/osc-icon';
import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import type { GetHotelHomePageQuery } from '../../generated/types';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { TripAdvisor } from '../trip-advisor/tripAdvisor';
import { ArrivalTime, ContactUs } from './utility-rail-tab-panels';
import HotelChip from '../layout/HotelChip';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';

type UtilityRailTabbedType = {
  checkinInfo: NonNullable<GetHotelHomePageQuery['hotel']>['registration'];
  contactInfo: NonNullable<GetHotelHomePageQuery['hotel']>['contactInfo'];
  hotelName: NonNullable<GetHotelHomePageQuery['hotel']>['name'];
  reviewData?: NonNullable<GetHotelHomePageQuery['hotel']>['tripAdvisorLocationSummary'];
  showRenovationChipInUtilityRail: boolean;
  showTripAdvisorWidget?: boolean;
};

const UtilityRailTabbedSection = ({
  checkinInfo,
  contactInfo,
  reviewData,
  showRenovationChipInUtilityRail,
  showTripAdvisorWidget,
  wrapperClass,
}: TWithCustomTheme<UtilityRailTabbedType>) => {
  const { t } = useTranslation('dx-utility-rail');
  const { isTailored } = useGlobalConfig();

  const initialTab = useCallback(() => {
    if (showTripAdvisorWidget) {
      return 'reviews';
    } else if (contactInfo) {
      return 'contact';
    } else {
      return 'arrival-time';
    }
  }, [showTripAdvisorWidget, contactInfo]);

  const [activeTab, setActiveTab] = useState<string | undefined>(initialTab);

  const email = contactInfo?.marketingEmail;
  const phoneNumber = contactInfo?.phoneNumber;
  const getContactTabTitle = () => {
    if (email && phoneNumber) {
      return t('contactUs');
    } else if (phoneNumber) {
      return t('call');
    } else if (email) {
      return t('email');
    }
  };

  const isActive = (tabId: string) => tabId === activeTab;

  const activeTablistButtonClassName = cx(
    'border-solid mx-1',
    {
      'bg-bg': isTailored,
      'text-primary-alt !border-primary-alt': !isTailored,
    },
    wrapperClass
  );

  const inactiveTablistButtonClassName = cx('mx-1', {
    'bg-bg': isTailored,
  });

  useEffect(() => {
    setActiveTab(initialTab());
  }, [initialTab]);

  return (
    <Tabs
      className={cx(
        'lg:hidden',
        {
          'border-border block border-t border-solid': !isTailored,
          'bg-bg': isTailored,
        },
        wrapperClass
      )}
      key={initialTab()}
      defaultActive={initialTab()}
      data-testid="test-tabbed-utility-rail"
      onTabChange={(id) => {
        setActiveTab(id);
      }}
    >
      {showRenovationChipInUtilityRail ? (
        <HotelChip className="flex justify-center pb-4 pt-6" variant="renovated" />
      ) : null}
      <TabList className="items-end">
        {showTripAdvisorWidget ? (
          <TabListButton
            id="reviews"
            activeClassName={activeTablistButtonClassName}
            inactiveClassName={inactiveTablistButtonClassName}
          >
            <div className="brand-ou:uppercase brand-ou:font-mono brand-ou:font-normal flex flex-col items-center justify-center font-bold">
              <Icon
                className={cx({ hidden: isActive('reviews') })}
                name="contact"
                size="sm"
                variant="regular"
              />
              <Icon
                className={cx({ hidden: !isActive('reviews') })}
                name="contact"
                size="sm"
                variant="solid"
              />
              <p className="pt-2 text-sm">{t('reviews')}</p>
            </div>
          </TabListButton>
        ) : null}
        {contactInfo ? (
          <TabListButton
            id="contact"
            activeClassName={activeTablistButtonClassName}
            inactiveClassName={inactiveTablistButtonClassName}
          >
            <div
              className={cx('flex flex-col items-center justify-center font-bold capitalize', {
                'brand-ou:uppercase brand-ou:font-mono brand-ou:font-normal ': isTailored,
              })}
            >
              <Icon
                className={cx({ hidden: isActive('contact') })}
                name="telephone"
                size="sm"
                variant="regular"
              />
              <Icon
                className={cx({ hidden: !isActive('contact') })}
                name="telephone"
                size="sm"
                variant="solid"
              />
              <p aria-hidden className="pt-2 text-sm">
                {getContactTabTitle()}
              </p>
              <p className="sr-only">{getContactTabTitle()}</p>
            </div>
          </TabListButton>
        ) : null}
        <TabListButton
          id="arrival-time"
          activeClassName={activeTablistButtonClassName}
          inactiveClassName={inactiveTablistButtonClassName}
        >
          <div
            className={cx('flex flex-col items-center justify-center font-bold capitalize', {
              'brand-ou:uppercase brand-ou:font-mono brand-ou:font-normal': isTailored,
            })}
          >
            <Icon
              className={cx({ hidden: isActive('arrival-time') })}
              name="clock"
              size="sm"
              variant="regular"
            />
            <Icon
              className={cx({ hidden: !isActive('arrival-time') })}
              name="clock"
              size="sm"
              variant="solid"
            />
            <p className="pt-2 text-sm">{t('arrivalTime')}</p>
          </div>
        </TabListButton>
      </TabList>
      <TabPanels
        className={cx('text-text border-t py-6 lg:py-8', {
          'bg-bg border-border-alt': isTailored,
        })}
      >
        {showTripAdvisorWidget ? (
          <TabPanel className="flex justify-center" id="reviews">
            <TripAdvisor
              shouldDisplayInRow={isTailored}
              reviewData={reviewData}
              wrapperClass={wrapperClass}
            />
          </TabPanel>
        ) : null}
        {contactInfo ? (
          <TabPanel className="flex justify-center" id="contact">
            <ContactUs
              email={contactInfo?.marketingEmail || ''}
              networkDisclaimer={contactInfo?.networkDisclaimer ?? ''}
              phoneNumber={contactInfo?.phoneNumber || ''}
              wrapperClass={wrapperClass}
            />
          </TabPanel>
        ) : null}
        <TabPanel className="flex justify-center" id="arrival-time">
          <ArrivalTime checkinInfo={checkinInfo} wrapperClass={wrapperClass} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export { UtilityRailTabbedSection };
